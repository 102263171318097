import { useCallback, useEffect } from 'react';

import { useClipboard } from '@chakra-ui/react';

import { useToast } from '../../feedback/toast/use-toast';

type UseCopyToClipBoardOptions = {
  /**
   * Show a confirmation toast after the value has been copied to the clipboard, default `true`
   */
  hasToast?: boolean;

  /**
   * Called after copy
   * @returns
   */
  onCopied?: () => void;
};

/**
 *
 * @param value The value to copy to the clipboard
 * @param options.hasToast Show a confirmation toast after the value has been copied to the clipboard, default `true`
 * @returns A function to call to trigger to copy
 * @see https://chakra-ui.com/docs/hooks/use-clipboard
 */
export const useCopyToClipBoard = (
  value: string,
  { hasToast = true, onCopied }: UseCopyToClipBoardOptions = {},
): (() => void) => {
  const toast = useToast();
  const { onCopy, setValue } = useClipboard(value);

  // Value must be manually synchronized to useClipboard using setValue
  // @see https://chakra-ui.com/docs/hooks/use-clipboard
  useEffect(() => {
    setValue(value);
  }, [setValue, value]);

  const copyToClipBoard = useCallback(() => {
    onCopy();
    if (hasToast) {
      toast({
        title: 'Copied!',
        description: `'${value}' has been successfully copied`,
        status: 'success',
        duration: 5000,
      });
    }
    onCopied?.();
  }, [hasToast, onCopy, toast, value, onCopied]);

  return copyToClipBoard;
};
