import { nanoid, urlAlphabet } from 'nanoid';

import { API_KEY_TOKEN_SEPARATOR } from './api-key-token';

export const API_KEY_SECRET_LENGTH = 21; // make the default size of nanoid explicit
export const API_KEY_SECRET_ALPHABET = urlAlphabet; // make the default alphabet of nanoid explicit

export const API_KEY_SECRET_PREFIX = `dotkey${API_KEY_TOKEN_SEPARATOR}`; // make dotfile api key easy to spot

export const generateApiKeySecret = (): string =>
  API_KEY_SECRET_PREFIX + nanoid(API_KEY_SECRET_LENGTH);
