import { Suspense } from 'react';
import { HelmetProvider } from 'react-helmet-async';

import {
  AnalyticsProvider,
  EnvironmentContextProvider,
  nonceId,
} from '@dotfile/frontend/shared/common';
import {
  CSPHeaders,
  ErrorBoundary,
  InfoDialog,
  InProgressUploadContextProvider,
  PdfWorker,
} from '@dotfile/frontend/shared/components';

import { environment } from '../environments/environment';
import {
  ClientPortalApp,
  ClientPortalIdResolvedGuard,
  RouterProvider,
} from './routes';
import {
  AppLoader,
  ClientPortalIdProvider,
  ContactAuthProvider,
  GenericErrorScreen,
  GraphQLProvider,
  HttpProvider,
  HttpProviderWithoutAuth,
  I18nProvider,
  InitDatadogRum,
  LoadLatestClientPortalVersion,
  QueryClientProvider,
  SyncAuth,
  SyncClientPortalId,
  ThemeDesignSystemProvider,
  UnstyledErrorScreen,
} from './shared';

export const Bootstrap = () => {
  return (
    <ErrorBoundary
      renderError={({ error }) => <UnstyledErrorScreen error={error} />}
    >
      <HelmetProvider>
        <CSPHeaders
          nonce={nonceId}
          environment={environment}
          allowBlob
          allowUbble
          allowDatadogRUM
        />
        <I18nProvider>
          <ThemeDesignSystemProvider>
            <EnvironmentContextProvider value={environment}>
              <QueryClientProvider>
                <HttpProviderWithoutAuth>
                  <InitDatadogRum>
                    <ClientPortalIdProvider>
                      <RouterProvider>
                        <AnalyticsProvider
                          writeKey={environment.analyticsWriteKey || ''}
                          dataPlaneUrl={environment.analyticsDataPlane || ''}
                        >
                          <InProgressUploadContextProvider>
                            <PdfWorker>
                              <ContactAuthProvider>
                                <GraphQLProvider>
                                  <HttpProvider>
                                    <Suspense fallback={<AppLoader />}>
                                      <ErrorBoundary
                                        renderError={({ error }) => (
                                          <GenericErrorScreen
                                            error={error}
                                            withMainLayout
                                          />
                                        )}
                                      >
                                        <ClientPortalIdResolvedGuard>
                                          <SyncClientPortalId />
                                          <LoadLatestClientPortalVersion>
                                            <SyncAuth>
                                              <ClientPortalApp />
                                            </SyncAuth>
                                          </LoadLatestClientPortalVersion>
                                        </ClientPortalIdResolvedGuard>
                                        <InfoDialog noDotfileMention />
                                      </ErrorBoundary>
                                    </Suspense>
                                  </HttpProvider>
                                </GraphQLProvider>
                              </ContactAuthProvider>
                            </PdfWorker>
                          </InProgressUploadContextProvider>
                        </AnalyticsProvider>
                      </RouterProvider>
                    </ClientPortalIdProvider>
                  </InitDatadogRum>
                </HttpProviderWithoutAuth>
              </QueryClientProvider>
            </EnvironmentContextProvider>
          </ThemeDesignSystemProvider>
        </I18nProvider>
      </HelmetProvider>
    </ErrorBoundary>
  );
};
