import {
  ClientPortalBlockFieldModel,
  ClientPortalBlockLayoutModel,
  ClientPortalBlockTypeEnum,
} from '../../../../shared/models';

export const isBlockField = <
  T extends {
    type?: ClientPortalBlockTypeEnum;
  },
>(
  maybeField: T,
): maybeField is T & ClientPortalBlockFieldModel =>
  maybeField.type === ClientPortalBlockTypeEnum.field ||
  ('mapping' in maybeField && !!maybeField.mapping);

export const isBlockLayout = (maybeLayout: {
  type?: ClientPortalBlockTypeEnum;
}): maybeLayout is ClientPortalBlockLayoutModel =>
  maybeLayout.type === ClientPortalBlockTypeEnum.layout ||
  ('widget' in maybeLayout && !!maybeLayout.widget);
