import { Copy } from 'lucide-react';
import { SyntheticEvent, useState } from 'react';
import { MarkOptional } from 'ts-essentials';

import { FormControl, FormLabel } from '../../form/form-control/form-control';
import { IconButton } from '../../form/icon-button/icon-button';
import { Input } from '../../form/input/input';
import { Text } from '../../typography/text';
import { useCopyToClipBoard } from '../../utils/hooks/use-copy-to-clipboard';
import { ConfirmDialog, ConfirmDialogProps } from './confirm-dialog';

export interface DeleteDialogProps
  extends MarkOptional<
    Omit<
      ConfirmDialogProps,
      | 'leastDestructiveRef'
      | 'onConfirm'
      | 'confirmText'
      | 'confirmColorScheme'
      | 'isConfirmDisabled'
    >,
    'cancelText'
  > {
  onDelete: (e: SyntheticEvent) => Promise<void>;
  deleteText?: string;
  questionText?: string;
  typeToConfirmText?: string;
}

export const DeleteDialog: React.FC<DeleteDialogProps> = ({
  onDelete,
  deleteText = 'Yes, delete it',
  questionText = 'Do you confirm the delete?',
  typeToConfirmText,
  cancelText = 'No, keep it',
  children,
  ...props
}: DeleteDialogProps) => {
  const [typeToConfirmValue, setTypeToConfirmValue] = useState('');

  const cleanedTypeToConfirmText = typeToConfirmText?.trim();
  const copyToClipBoard = useCopyToClipBoard(cleanedTypeToConfirmText ?? '');

  return (
    <ConfirmDialog
      onConfirm={onDelete}
      cancelText={cancelText}
      confirmText={deleteText}
      confirmColorScheme="red"
      isConfirmDisabled={
        !!cleanedTypeToConfirmText &&
        cleanedTypeToConfirmText !== typeToConfirmValue
      }
      {...props}
    >
      <Text as="div" textAlign="start">
        {/* @NOTE: Children could be a list and ul cannot appear as a p descendant */}
        {children}
      </Text>
      <Text>{questionText}</Text>

      {cleanedTypeToConfirmText && (
        <FormControl>
          <FormLabel htmlFor="type-to-confirm">
            Type{' '}
            <Text
              as="pre"
              display="inline"
              wordBreak="break-word"
              whiteSpace="pre-wrap"
              backgroundColor="gray.200"
              borderRadius="md"
              padding="0.5"
            >
              {cleanedTypeToConfirmText}
            </Text>
            <IconButton
              variant="ghost"
              aria-label="copy"
              icon={<Copy size="16px" />}
              onClick={copyToClipBoard}
              size="xs"
            />{' '}
            to confirm:
          </FormLabel>

          <Input
            id="type-to-confirm"
            name="type-to-confirm"
            value={typeToConfirmValue}
            onChange={(e) => {
              setTypeToConfirmValue(e.target.value);
            }}
          />
        </FormControl>
      )}
    </ConfirmDialog>
  );
};
