import { useCallback, useEffect, useRef } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  Button,
  Center,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  useIsMobile,
  useToast,
  VStack,
} from '@dotfile/frontend/shared/design-system';
import { formatDate, nameFormat } from '@dotfile/shared/common';
import {
  CheckResultEnum,
  ClientPortalChecks_IdVerificationCheck,
  ClientPortalChecks_Individual,
} from '@dotfile/shared/data-access-client-portal';

import { CheckModalHeader, useIsUbbleCheckoutSdkLoaded } from '../shared';
import {
  DataComparisonAlert,
  DownloadFiles,
  IsItTheRightDocumentForIndividual,
  IsItTheRightDocumentRadioCard,
  UpdateReferenceDataControllers,
} from './components';
import {
  UBBLE_IFRAME_HEIGHT,
  UBBLE_IFRAME_HEIGHT_MOBILE,
} from './components/constants';
import { UbbleCheckoutIframe } from './components/ubble-checkout-iframe';
import {
  useRefreshIdVerificationCheck,
  useUpdateReferenceDataForm,
  useVerificationUrl,
} from './hooks';

type IdVerificationModalProps = {
  check: ClientPortalChecks_IdVerificationCheck;
  individual: ClientPortalChecks_Individual;
  isOpen: boolean;
  onClose: () => void;
};

export const IdVerificationModal = ({
  check,
  individual,
  isOpen,
  onClose,
}: IdVerificationModalProps) => {
  const { loaded } = useIsUbbleCheckoutSdkLoaded();

  const isMobile = useIsMobile();
  const { t, i18n } = useTranslation();
  const toast = useToast();

  const { refresh, verificationUrl, loading, error } =
    useVerificationUrl(check);

  const isLoading = !loaded || loading;

  const individualFullName = nameFormat(
    individual.firstName,
    individual.lastName,
  );
  const individualBirthDate = individual.birthDate
    ? formatDate(individual.birthDate, {
        locale: i18n.language,
      })
    : null;

  if (error) {
    throw error; // will be handled by Error boundary
  }

  const updateReferenceDataForm = useUpdateReferenceDataForm(check, individual);
  const showDataComparisonMismatch =
    check.data.detailedResults.document.dataComparison ===
      CheckResultEnum.rejected &&
    updateReferenceDataForm.isRightDocumentForIndividual !== 'no';

  // Refresh VerificationURL at start
  const isInitialMount = useRef(true);
  useEffect(() => {
    if (
      !verificationUrl &&
      !showDataComparisonMismatch &&
      isInitialMount.current
    ) {
      // @NOTE Guarantee that we call only once the refresh at loading
      // because we have seen that our vendor return 500 error when we do concurrent calls to them
      // and guarantee the refresh is not call at the end onComplete, onAbort nor onExpired
      isInitialMount.current = false;
      refresh();
    }
  }, [refresh, verificationUrl, loading, showDataComparisonMismatch]);

  const [refetchIdVerificationCheck] = useRefreshIdVerificationCheck(check.id);
  const onComplete = useCallback(async () => {
    await refetchIdVerificationCheck();

    onClose();
  }, [onClose, refetchIdVerificationCheck]);

  const onAbort = useCallback(async () => {
    toast({
      title: t('checks.id_verification.aborted', {
        ns: 'client-portal',
        defaultValue: 'Identity verification aborted',
      }),
      status: 'warning',
    });

    await onComplete();
  }, [onComplete, toast, t]);

  const onExpired = useCallback(async () => {
    toast({
      title: t('checks.id_verification.expired', {
        ns: 'client-portal',
        defaultValue: 'Identity verification expired',
      }),
      status: 'warning',
    });

    await onComplete();
  }, [onComplete, toast, t]);

  return (
    <Modal
      variant="dialog-white"
      isOpen={isOpen}
      size={isMobile ? 'full' : '2xl'}
      onClose={onComplete}
      isCentered
      scrollBehavior="inside"
      closeOnOverlayClick={false}
      closeOnEsc={false}
    >
      <ModalOverlay />

      <ModalContent>
        <ModalHeader>
          <CheckModalHeader check={check} />
        </ModalHeader>
        <ModalBody position="relative">
          {showDataComparisonMismatch ? (
            <VStack align="stretch" as="form" w="100%" spacing="4">
              <DataComparisonAlert />

              <IsItTheRightDocumentForIndividual
                individualFullName={individualFullName}
                individualBirthDate={individualBirthDate}
              />

              <DownloadFiles {...check.data.information} />

              <IsItTheRightDocumentRadioCard
                value={updateReferenceDataForm.isRightDocumentForIndividual}
                onChange={
                  updateReferenceDataForm.setIsRightDocumentForIndividual
                }
              />

              {updateReferenceDataForm.isRightDocumentForIndividual ===
                'yes' && (
                <FormProvider {...updateReferenceDataForm.methods}>
                  <UpdateReferenceDataControllers
                    isBirthDateRequired={
                      updateReferenceDataForm.isBirthDateRequired
                    }
                  />
                </FormProvider>
              )}
            </VStack>
          ) : isLoading || !verificationUrl ? (
            <Center
              height={
                isMobile ? UBBLE_IFRAME_HEIGHT_MOBILE : UBBLE_IFRAME_HEIGHT
              }
            >
              <Spinner size="xl" color="custom.500" />
            </Center>
          ) : (
            <UbbleCheckoutIframe
              verificationUrl={verificationUrl}
              onComplete={onComplete}
              onAbort={onAbort}
              onExpired={onExpired}
            />
          )}
        </ModalBody>
        <ModalFooter>
          {showDataComparisonMismatch ? (
            <HStack spacing={4}>
              <Button size="md" onClick={onClose} variant="ghost">
                {t('common.cancel', {
                  defaultValue: 'Cancel',
                  ns: 'client-portal',
                })}
              </Button>

              <Button
                size="md"
                isDisabled={updateReferenceDataForm.submitIsDisabled}
                isLoading={updateReferenceDataForm.isSubmitting}
                onClick={updateReferenceDataForm.handleSubmit}
                autoFocus
              >
                {t('common.confirm', {
                  ns: 'client-portal',
                  defaultValue: 'Confirm',
                })}
              </Button>
            </HStack>
          ) : (
            <Button variant="ghost" onClick={onClose}>
              {t('common.cancel', {
                defaultValue: 'Cancel',
                ns: 'client-portal',
              })}
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
