import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { GroupController } from '@dotfile/frontend/shared/components';
import {
  CountrySelect,
  Grid,
  GridItem,
  Input,
  Text,
  useIsMobile,
  VStack,
} from '@dotfile/frontend/shared/design-system';
import { PropertyTypeEnum } from '@dotfile/shared/domain';

import { FieldProps } from './types';

export const FieldAddress = ({
  field,
  property,

  helper,
  label,
  name,

  wrapperProps,
  ...groupControlProps
}: FieldProps<typeof PropertyTypeEnum.address>) => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const { control } = useFormContext();

  return (
    <VStack align="stretch" my="2" width="full" {...wrapperProps}>
      <Text color="black" fontWeight="bold">
        {label}
      </Text>
      {!!helper && <Text>{helper}</Text>}

      <Grid
        templateColumns={isMobile ? 'repeat(1, 1fr)' : 'repeat(2, 1fr)'}
        gap={isMobile ? '2' : '4'}
      >
        <GridItem>
          <GroupController
            control={control}
            render={(field) => (
              <Input type="text" {...field} required={false} />
            )}
            {...groupControlProps}
            name={`${name}.streetAddress`}
            label={t('forms.field.address.street_address.label', {
              ns: 'client-portal',
              defaultValue: 'Street address',
            })}
          />
        </GridItem>
        <GridItem>
          <GroupController
            control={control}
            render={(field) => (
              <Input type="text" {...field} required={false} />
            )}
            {...groupControlProps}
            isRequired={false} // Never required even when the address itself is required
            name={`${name}.streetAddress2`}
            label={t('forms.field.address.street_address2.label', {
              ns: 'client-portal',
              defaultValue: 'Street address 2',
            })}
          />
        </GridItem>
        <GridItem>
          <GroupController
            control={control}
            render={(field) => (
              <Input type="text" {...field} required={false} />
            )}
            {...groupControlProps}
            name={`${name}.postalCode`}
            label={t('forms.field.address.postal_code.label', {
              ns: 'client-portal',
              defaultValue: 'Postal code',
            })}
          />
        </GridItem>
        <GridItem>
          <GroupController
            control={control}
            render={(field) => (
              <Input type="text" {...field} required={false} />
            )}
            {...groupControlProps}
            name={`${name}.city`}
            label={t('forms.field.address.city.label', {
              ns: 'client-portal',
              defaultValue: 'City',
            })}
          />
        </GridItem>
        <GridItem>
          <GroupController
            control={control}
            render={(field) => (
              <Input type="text" {...field} required={false} />
            )}
            {...groupControlProps}
            isRequired={false} // Never required even when the address itself is required
            name={`${name}.state`}
            label={t('forms.field.address.state.label', {
              ns: 'client-portal',
              defaultValue: 'State',
            })}
          />
        </GridItem>
        <GridItem>
          <GroupController
            control={control}
            render={({ value, ...field }) => (
              <CountrySelect
                {...field}
                onChange={(updatedValue) => {
                  field.onChange(updatedValue?.value ?? null, {
                    shouldDirty: true,
                    shouldValidate: true,
                  });
                }}
                placeholder={t('forms.field.countries.placeholder', {
                  ns: 'client-portal',
                  defaultValue: 'Select ...',
                })}
                defaultValue={value}
                required={false}
              />
            )}
            {...groupControlProps}
            name={`${name}.country`}
            label={t('forms.field.address.country.label', {
              ns: 'client-portal',
              defaultValue: 'Country',
            })}
          />
        </GridItem>
      </Grid>
    </VStack>
  );
};
