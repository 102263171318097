export const CheckStatusEnum = {
  in_progress: 'in_progress',
  need_review: 'need_review',
  processing: 'processing',
  approved: 'approved',
  rejected: 'rejected',
  /**+
   * @deprecated will be removed in Q1'25 with rework check status project
   * replaced by a flag instead of a check status
   */
  expired: 'expired',
} as const;
export type CheckStatusEnum =
  (typeof CheckStatusEnum)[keyof typeof CheckStatusEnum];
