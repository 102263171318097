import { ClientPortalBlockLayoutWidgetEnum } from '../../../../shared/models';

export const blockLayoutDefinition = {
  [ClientPortalBlockLayoutWidgetEnum.divider]: {
    icon: 'separator-horizontal',
    label: 'Divider',
  },
  [ClientPortalBlockLayoutWidgetEnum.pdf]: {
    icon: 'file-text',
    label: 'PDF',
  },
  [ClientPortalBlockLayoutWidgetEnum.text]: {
    icon: 'text-cursor',
    label: 'Text',
  },
} as const satisfies Record<
  ClientPortalBlockLayoutWidgetEnum,
  {
    icon: string;
    label: string;
  }
>;
