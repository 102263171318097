import 'react-datepicker/dist/react-datepicker.css';
import { ValueEditorProps } from 'react-querybuilder';
import { MarkRequired } from 'ts-essentials';
import { match } from 'ts-pattern';

import { ChakraValueEditor } from '@react-querybuilder/chakra';

import { PropertyTypeEnum, QueryBuilderOperator } from '@dotfile/shared/domain';

import { Box } from '../../layout/box/box';
import { YesNoButton } from '../radio-button';
import { TypedQueryBuilderField } from './type';
import { ValueEditorCountry } from './value-editor-countries';
import { ValueEditorDate } from './value-editor-date';
import { ValueEditorEntityLegalForm } from './value-editor-entity-legal-form';
import { ValueEditorNumeric } from './value-editor-numeric';
import { ValueSelector } from './value-selector';

const extraProps = { backgroundColor: 'white' };

export const ValueEditor = (
  props: ValueEditorProps<TypedQueryBuilderField, QueryBuilderOperator>,
): JSX.Element | null => {
  if (['null', 'notNull'].includes(props.operator)) {
    // No value selector for null/notNull so returns a simple box as a place holder
    return <Box />;
  }

  const multiple = [
    'in',
    'notIn',
    'arrayContains',
    'arrayContainsAll',
    'doesNotArrayContain',
    'doesNotArrayContainAll',
  ].includes(props.operator);

  return (
    match(props.fieldData)
      .when(
        (fieldData): fieldData is MarkRequired<typeof fieldData, 'component'> =>
          !!fieldData.component,
        (fieldData) => {
          return (
            <fieldData.component
              {...props}
              multiple={multiple}
              fieldData={fieldData}
            />
          );
        },
      )
      .when(isFieldDataType(PropertyTypeEnum.boolean), () => {
        return (
          <YesNoButton
            onChange={props.handleOnChange}
            defaultValue={props.value}
          />
        );
      })

      .when(isFieldDataType(PropertyTypeEnum.choices), () => {
        return (
          <ValueSelector
            {...props}
            multiple={multiple}
            options={props.values ?? []}
          />
        );
      })

      .when(isFieldDataType(PropertyTypeEnum.countries), (fieldData) => (
        <ValueEditorCountry
          {...props}
          multiple={multiple}
          fieldData={fieldData}
        />
      ))

      .when(
        isFieldDataType(PropertyTypeEnum.entity_legal_form),
        (fieldData) => (
          <ValueEditorEntityLegalForm
            {...props}
            multiple={multiple}
            fieldData={fieldData}
          />
        ),
      )

      .when(isFieldDataType(PropertyTypeEnum.date), (fieldData) => (
        <ValueEditorDate {...props} fieldData={fieldData} />
      ))

      .when(isFieldDataType(PropertyTypeEnum.numeric), (fieldData) => (
        <ValueEditorNumeric {...props} fieldData={fieldData} />
      ))

      // Fallback to builtin ChakraValueEditor
      .otherwise(() => {
        return (
          <ChakraValueEditor
            {
              // ChakraValueEditor props doesn't have generic for ValueEditorProps
              ...(props as unknown as ValueEditorProps)
            }
            extraProps={extraProps}
          />
        );
      })
  );
};

const isFieldDataType =
  <TPropertyType extends PropertyTypeEnum>(type: TPropertyType) =>
  (
    fieldData: TypedQueryBuilderField,
  ): fieldData is TypedQueryBuilderField<TPropertyType> =>
    fieldData.type === type;
