import { DeepPick } from 'ts-essentials';

import {
  ApiKeyModel,
  CaseModel,
  ClientPortalModel,
  IndividualModel,
  TemplateModel,
  UserModel,
  WorkspaceModel,
} from '../models';

export const ContextTypeEnum = {
  user: 'user',
  workspace: 'workspace',
  system: 'system',
  client_portal: 'client_portal',
  client_portal_public: 'client_portal_public',
  template: 'template',
  workflow: 'workflow',
  unknown: 'unknown',
} as const;
export type ContextTypeEnum =
  (typeof ContextTypeEnum)[keyof typeof ContextTypeEnum];

export type Context =
  | UnknownContext
  | UserContext
  | WorkspaceContext
  | SystemContext
  | ClientPortalContext
  | ClientPortalPublicContext
  | TemplateContext
  | WorkflowContext;
export type SerializedContext =
  | CreateSerializedContext<UnknownContext>
  | CreateSerializedContext<UserContext>
  | CreateSerializedContext<WorkspaceContext>
  | CreateSerializedContext<SystemContext>
  | CreateSerializedContext<ClientPortalContext>
  | CreateSerializedContext<ClientPortalPublicContext>
  | CreateSerializedContext<TemplateContext>
  | CreateSerializedContext<WorkflowContext>;

export type TypedContext<T extends ContextTypeEnum> =
  T extends typeof ContextTypeEnum.unknown ? Context : Context & { type: T };

export type ContextWorkspace = DeepPick<
  WorkspaceModel,
  {
    id: true;
    name: true;
    plan: true;
    createdAt: true;
    settings: {
      mfa: {
        isEnforced: true;
      };
    };
  }
>;
export type ContextClientPortal = Pick<
  ClientPortalModel,
  'id' | 'name' | 'status' | 'type'
>;

/**
 * Default Context
 */
export interface UnknownContext {
  type: typeof ContextTypeEnum.unknown;
  workspace: ContextWorkspace | null;
}

/**
 * User context for Console
 */
export interface UserContext {
  type: typeof ContextTypeEnum.user;
  workspace: ContextWorkspace | null;
  user: DeepPick<
    UserModel,
    {
      id: true;
      firstName: true;
      lastName: true;
      email: true;
      createdAt: true;
      mfa: {
        authenticator: {
          isEnabled: true;
        };
        email: {
          isEnabled: true;
        };
      };
    }
  >;
}

/**
 * For incoming hooks, Workers or Internal system actions
 */
export interface SystemContext {
  type: typeof ContextTypeEnum.system;
  workspace: ContextWorkspace;
  clientPortal?: ContextClientPortal;
}

/**
 * For template run
 */
export interface TemplateContext {
  type: typeof ContextTypeEnum.template;
  workspace: ContextWorkspace;
  template: Pick<TemplateModel, 'id'>;
}

/**
 * For api
 */
export interface WorkspaceContext {
  type: typeof ContextTypeEnum.workspace;
  workspace: ContextWorkspace;
  apiKey: Pick<ApiKeyModel, 'id' | 'name' | 'createdAt'>;
}

/**
 * For client portal
 */
export interface ClientPortalContext {
  type: typeof ContextTypeEnum.client_portal;
  workspace: ContextWorkspace;
  clientPortal: ContextClientPortal;
  case: Pick<CaseModel, 'id'>;
  contact: Pick<
    IndividualModel,
    'id' | 'firstName' | 'lastName' | 'email' | 'isRelevant'
  >;
}

/**
 * For client portal in public/not authenticated mode
 */
export interface ClientPortalPublicContext {
  type: typeof ContextTypeEnum.client_portal_public;
  workspace: ContextWorkspace;
  clientPortal: ContextClientPortal;
}

/**
 * For workflow execution
 */
export interface WorkflowContext {
  type: typeof ContextTypeEnum.workflow;
  workspace: ContextWorkspace;
  clientPortal: ContextClientPortal;
  case: Pick<CaseModel, 'id'>;
}

/**
 * Create a new TypeScript type for Context with the same `type` and only the id of each property
 * renamed to `{property}Id` with the same optionality/nullability
 *
 * @see https://www.typescriptlang.org/docs/handbook/2/mapped-types.html#key-remapping-via-as
 */
type CreateSerializedContext<C extends Context> = {
  [K in keyof C as K extends 'type' ? K : `${K & string}Id`]: K extends 'type'
    ? C[K]
    : C[K] extends { id: string }
      ? string
      : C[K] extends null | { id: string }
        ? string | null
        : C[K] extends undefined | null | { id: string }
          ? undefined | null | string
          : never;
};
