export const ActivityTypeEnum = {
  // on case
  api__created__case: 'api__created__case',
  contact__created__case: 'contact__created__case',
  user__created__case: 'user__created__case',

  api__updated_info__case: 'api__updated_info__case',
  contact__updated_info__case: 'contact__updated_info__case',
  user__updated_info__case: 'user__updated_info__case',

  api__updated_status__case: 'api__updated_status__case',
  user__updated_status__case: 'user__updated_status__case',
  system__updated_status__case: 'system__updated_status__case',

  api__updated_template__case: 'api__updated_template__case',
  user__updated_template__case: 'user__updated_template__case',
  system__updated_template__case: 'system__updated_template__case',
  workflow__updated_template__case: 'workflow__updated_template__case',

  api__updated_assignee__case: 'api__updated_assignee__case',
  user__updated_assignee__case: 'user__updated_assignee__case',

  api__updated_metadata__case: 'api__updated_metadata__case',
  contact__updated_metadata__case: 'contact__updated_metadata__case',
  user__updated_metadata__case: 'user__updated_metadata__case',

  api__updated_risk__case: 'api__updated_risk__case',
  user__updated_risk__case: 'user__updated_risk__case',

  system__updated_review__case: 'system__updated_review__case',

  api__confirmed_review__case: 'api__confirmed_review__case',
  system__confirmed_review__case: 'system__confirmed_review__case',
  user__confirmed_review__case: 'user__confirmed_review__case',

  api__deleted__case: 'api__deleted__case',
  user__deleted__case: 'user__deleted__case',

  api__added_tags__case: 'api__added_tags__case',
  user__added_tags__case: 'user__added_tags__case',
  workflow__added_tags__case: 'workflow__added_tags__case',
  api__removed_tags__case: 'api__removed_tags__case',
  user__removed_tags__case: 'user__removed_tags__case',

  // on case relation
  api__updated__case_relation: 'api__updated__case_relation',
  contact__updated__case_relation: 'contact__updated__case_relation',
  user__updated__case_relation: 'user__updated__case_relation',

  // on communication
  user__sent__communication: 'user__sent__communication',
  system__sent__communication: 'system__sent__communication',
  system__received__communication: 'system__received__communication',

  // on individual
  api__created__individual: 'api__created__individual',
  contact__created__individual: 'contact__created__individual',
  user__created__individual: 'user__created__individual',

  api__updated__individual: 'api__updated__individual',
  contact__updated__individual: 'contact__updated__individual',
  user__updated__individual: 'user__updated__individual',

  api__marked_as_relevant__individual: 'api__marked_as_relevant__individual',
  user__marked_as_relevant__individual: 'user__marked_as_relevant__individual',

  api__marked_as_not_relevant__individual:
    'api__marked_as_not_relevant__individual',
  contact__marked_as_not_relevant__individual:
    'contact__marked_as_not_relevant__individual',
  user__marked_as_not_relevant__individual:
    'user__marked_as_not_relevant__individual',

  contact__sent_collaboration_email__individual:
    'contact__sent_collaboration_email__individual',

  api__deleted__individual: 'api__deleted__individual',
  user__deleted__individual: 'user__deleted__individual',

  // on company
  api__created__company: 'api__created__company',
  contact__created__company: 'contact__created__company',
  user__created__company: 'user__created__company',

  api__updated__company: 'api__updated__company',
  contact__updated__company: 'contact__updated__company',
  user__updated__company: 'user__updated__company',

  api__marked_as_relevant__company: 'api__marked_as_relevant__company',
  user__marked_as_relevant__company: 'user__marked_as_relevant__company',

  api__marked_as_not_relevant__company: 'api__marked_as_not_relevant__company',
  contact__marked_as_not_relevant__company:
    'contact__marked_as_not_relevant__company',
  user__marked_as_not_relevant__company:
    'user__marked_as_not_relevant__company',

  api__deleted__company: 'api__deleted__company',
  user__deleted__company: 'user__deleted__company',

  // on company data
  user__purchased__company_data: 'user__purchased__company_data',

  // on check
  api__started__check: 'api__started__check',
  contact__started__check: 'contact__started__check',
  user__started__check: 'user__started__check',
  system__started__check: 'system__started__check',
  template__started__check: 'template__started__check',
  workflow__started__check: 'workflow__started__check',

  api__set_review_needed__check: 'api__set_review_needed__check',
  contact__set_review_needed__check: 'contact__set_review_needed__check',
  system__set_review_needed__check: 'system__set_review_needed__check',

  api__approved__check: 'api__approved__check',
  user__approved__check: 'user__approved__check',
  system__approved__check: 'system__approved__check',

  api__rejected__check: 'api__rejected__check',
  user__rejected__check: 'user__rejected__check',
  system__rejected__check: 'system__rejected__check',

  api__enabled_monitoring__check: 'api__enabled_monitoring__check',
  user__enabled_monitoring__check: 'user__enabled_monitoring__check',

  api__disabled_monitoring__check: 'api__disabled_monitoring__check',
  user__disabled_monitoring__check: 'user__disabled_monitoring__check',
  system__disabled_monitoring__check: 'system__disabled_monitoring__check',

  system__expired__check: 'system__expired__check',
  end_user__started_flow__check: 'end_user__started_flow__check',
  end_user__finished_flow__check: 'end_user__finished_flow__check',

  user__deleted__check: 'user__deleted__check',
  api__deleted__check: 'api__deleted__check',

  api__ignored__aml_hit: 'api__ignored__aml_hit',
  user__ignored__aml_hit: 'user__ignored__aml_hit',

  api__confirmed__aml_hit: 'api__confirmed__aml_hit',
  user__confirmed__aml_hit: 'user__confirmed__aml_hit',

  // on template
  /**
   * User Run Template manually (via Console)
   */
  user__run__template: 'user__run__template',
  /**
   * Run Template manually via API
   */
  api__run__template: 'api__run__template',
  /**
   * Template run automatically via listener (on entity creation)
   * @deprecated This activity is currently not created
   */
  system__run__template: 'system__run__template',

  // on client portal
  contact__completed_forms__client_portal:
    'contact__completed_forms__client_portal',
  contact__completed_checks__client_portal:
    'contact__completed_checks__client_portal',
} as const;
export type ActivityTypeEnum =
  (typeof ActivityTypeEnum)[keyof typeof ActivityTypeEnum];
