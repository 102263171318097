import * as Yup from 'yup';

import { UserModel } from '../../../../shared/models';
import {
  PASSWORD_MAX_LENGTH,
  PASSWORD_MIN_LENGTH,
  PASSWORD_MIN_SCORE,
} from '../password';

export const userSchema = (): Yup.SchemaOf<
  Pick<UserModel, 'firstName' | 'lastName' | 'email' | 'password'> & {
    _passwordScore: number;
  }
> => {
  return Yup.object().shape({
    firstName: Yup.string().required(),
    lastName: Yup.string().required(),
    email: Yup.string().email().required(),
    password: Yup.string()
      .min(PASSWORD_MIN_LENGTH)
      .max(PASSWORD_MAX_LENGTH)
      .required(),
    _passwordScore: Yup.number().min(PASSWORD_MIN_SCORE).required(),
  });
};

export const updateUserSchema = (): Yup.SchemaOf<
  Pick<UserModel, 'firstName' | 'lastName' | 'email'> & {
    avatarUploadRef: string | null;
  }
> =>
  userSchema().pick(['firstName', 'lastName', 'email']).shape({
    avatarUploadRef: Yup.string().nullable(),
  });

export const resetPasswordUserSchema = (): Yup.SchemaOf<
  Pick<UserModel, 'password'> & {
    _passwordScore: number;
  }
> => userSchema().pick(['password', '_passwordScore']);
