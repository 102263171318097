import { PartsStyleFunction } from '@chakra-ui/react';
import { ComponentStyleConfig } from '@chakra-ui/theme';
import { mode } from '@chakra-ui/theme-tools';

// see https://github.com/chakra-ui/chakra-ui/blob/main/packages/theme/src/components/input.ts
// for default ChakraUI theme
// @SEE https://github.com/chakra-ui/chakra-ui/issues/5653 to have CSS variables on inputs
// This will disabled the props focusBorderColor & errorBorderColor, prefer using CSS variables to override them

export const variantInline: PartsStyleFunction = (props) => {
  return {
    field: {
      borderRadius: 0,
      bg: 'white',
      px: 0,
      borderWidth: '0 0 1px 0',
      borderStyle: 'solid',
      borderColor: 'gray.500',

      _readOnly: {
        boxShadow: 'none !important',
        userSelect: 'all',
      },
      _invalid: {
        borderColor: mode('red.500', 'red.300')(props),
      },
      _focus: {
        borderWidth: '0 0 1px 0',
        borderStyle: 'solid',
        borderColor: mode('gray.800', 'gray.600')(props),
      },
    },
    addon: {
      borderBottom: '2px solid',
      borderColor: 'inherit',
      borderRadius: 0,
      px: 0,
      bg: 'white',
    },
  };
};

export const variantOutline: PartsStyleFunction = (props) => {
  return {
    field: {
      backgroundColor: 'white',
      _invalid: {
        bg: 'red.50',
        borderColor: mode('red.500', 'red.300')(props),
        boxShadow: 'none !important',
      },
      _focus: {
        borderColor: mode('gray.800', 'gray.600')(props),
        boxShadow: 'none !important',
      },
    },
  };
};

export const variantFilled: PartsStyleFunction = (props) => {
  return {
    field: {
      backgroundColor: 'gray.100',
      border: '1px solid',
      _invalid: {
        bg: 'red.50',
      },
      _focus: {
        borderColor: mode('gray.800', 'gray.600')(props),
      },
    },
    addon: {
      backgroundColor: 'gray.100',
      border: '1px solid',
    },
  };
};

export const variantFlushed: PartsStyleFunction = (props) => {
  return {
    field: {
      backgroundColor: 'white',
      _invalid: {
        bg: 'red.50',
        boxShadow: 'none !important',
      },
      _focus: {
        borderColor: mode('gray.800', 'gray.600')(props),
        boxShadow: 'none !important',
      },
    },
  };
};

export const InputTheme: ComponentStyleConfig = {
  variants: {
    inline: variantInline,
    outline: variantOutline,
    filled: variantFilled,
    flushed: variantFlushed,
  },
};
