export const ClientPortalBlockTypeEnum = {
  field: 'field',
  layout: 'layout',
} as const;
export type ClientPortalBlockTypeEnum =
  (typeof ClientPortalBlockTypeEnum)[keyof typeof ClientPortalBlockTypeEnum];

export const ClientPortalBlockLayoutWidgetEnum = {
  divider: 'divider',
  text: 'text',
  pdf: 'pdf',
} as const;
export type ClientPortalBlockLayoutWidgetEnum =
  (typeof ClientPortalBlockLayoutWidgetEnum)[keyof typeof ClientPortalBlockLayoutWidgetEnum];

export const ClientPortalBlockFieldDisplayEnum = {
  dropdown: 'dropdown',
  yes_no: 'yes_no',
  checkbox_radio: 'checkbox_radio',
} as const;
export type ClientPortalBlockFieldDisplayEnum =
  (typeof ClientPortalBlockFieldDisplayEnum)[keyof typeof ClientPortalBlockFieldDisplayEnum];
