import { Image as ImageIcon, UploadCloud } from 'lucide-react';
import { readableColor } from 'polished';
import { useEffect, useMemo, useState } from 'react';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import {
  BoxProps,
  Dropzone,
  DropzoneProps,
  Icon,
  Image,
  Text,
  useToast,
  useToken,
  VStack,
} from '@dotfile/frontend/shared/design-system';
import {
  allAcceptedFileTypes,
  FILE_DEFAULT_MAX_SIZE,
} from '@dotfile/shared/domain';

type UploadDropzoneProps = Omit<BoxProps, 'onDrop' | 'onKeyDown'> & {
  onDrop?: <T extends File>(file: T) => void;
  fileName?: string;
  description?: string;
  helper?: string;
  initialValue?: string | null;
  isFocused?: boolean;
  uploadProgress?: number;
  type: DropzoneProps['type'];
  isOnError?: boolean;
  renderPreview: (props: {
    fileName?: string;
    description: string;
    helper?: string | null;
    previewDataUrl: string | null;
  }) => React.ReactNode;
  maxSize?: number;
  accept?: DropzoneOptions['accept'];
};

/* -------------------------------------------------------------------------- */
/*                                   Generic                                  */
/* -------------------------------------------------------------------------- */
// @TODO - E-699 - To be able to remove media on the upload-dropzone
const GenericUploadDropzone = ({
  onDrop,
  fileName,
  description,
  helper,
  initialValue = null,
  renderPreview,
  uploadProgress,
  isFocused,
  type,
  isOnError,
  maxSize = FILE_DEFAULT_MAX_SIZE,
  // @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
  accept = {
    'image/*': allAcceptedFileTypes
      .filter((aft) => aft.mimeType.startsWith('image/'))
      .flatMap((aft) => aft.extensions),
    'text/*': allAcceptedFileTypes
      .filter((aft) => aft.mimeType.startsWith('text/'))
      .flatMap((aft) => aft.extensions),
    // application/* mime type must be manually specified, application/* is not valid
    ...Object.fromEntries(
      allAcceptedFileTypes
        .filter((aft) => aft.mimeType.startsWith('application/'))
        .map((aft) => [aft.mimeType, aft.extensions]),
    ),
  },
  ...props
}: UploadDropzoneProps) => {
  const toast = useToast();
  const { t } = useTranslation();
  const [previewDataUrl, setPreviewDataUrl] = useState<string | null>(
    initialValue,
  );

  // @doc https://react-dropzone.js.org/#src
  const { getRootProps, getInputProps, open, rootRef } = useDropzone({
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length === 0) {
        // @TODO - E-69 - Better handle error, toast VS inline
        toast({
          title: t('file.error.on_drop', {
            maxSize: maxSize / 1_000_000,
            defaultValue: `Something went wrong, please check your document size ({{max}}Mo) and format`,
            ns: 'components',
          }),
          status: 'error',
        });
      } else {
        onDrop && onDrop<File>(acceptedFiles[0]);

        setPreviewDataUrl(URL.createObjectURL(acceptedFiles[0]));
      }
    },
    maxSize,
    accept,
    // So far hard code these constraints
    maxFiles: 1,
    multiple: false,
    disabled: !onDrop,
  });

  useEffect(() => {
    setPreviewDataUrl(initialValue);
  }, [initialValue]);

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      if (previewDataUrl) URL.revokeObjectURL(previewDataUrl);
    },
    [previewDataUrl],
  );

  return (
    <Dropzone
      alignItems="center"
      justifyContent="center"
      isFocused={isFocused}
      uploadProgress={uploadProgress}
      type={type}
      {...props}
      {...getRootProps()}
      onKeyDown={(e) => {
        if (e.key === 'Escape') {
          rootRef.current?.blur();
        }
        if (e.code === 'Space' || e.key === 'Enter') {
          open();
        }
      }}
    >
      <input {...getInputProps()} />

      {renderPreview({
        fileName,
        description:
          description ??
          t('file.dropzone_description', {
            defaultValue: 'Drop your file here or browse',
            ns: 'components',
          }),
        previewDataUrl,
        helper,
      })}
    </Dropzone>
  );
};

/* -------------------------------------------------------------------------- */
/*                                    File                                    */
/* -------------------------------------------------------------------------- */
const FileUploadDropzone = (
  props: Omit<UploadDropzoneProps, 'renderPreview'>,
) => {
  return (
    <GenericUploadDropzone
      {...props}
      h={props.h ?? '100'}
      renderPreview={({ fileName, description, helper }) => (
        <span>
          <Icon as={UploadCloud} />
          <Text noOfLines={4} lineHeight="24px" wordBreak="break-word">
            {fileName ?? description}
          </Text>
          {helper && <Text color="gray.200">{helper}</Text>}
        </span>
      )}
    />
  );
};

/* -------------------------------------------------------------------------- */
/*                                    Logo                                    */
/* -------------------------------------------------------------------------- */
const LogoUploadDropzone = (
  props: Omit<UploadDropzoneProps, 'renderPreview'>,
) => {
  const w = props.w ?? '100px';
  const h = props.h ?? w;
  const [gray200, gray800] = useToken('colors', ['gray.200', 'gray.800']);
  const contrastColor = readableColor(
    (props.backgroundColor as string) ?? '#FFF',
    gray800,
    gray200,
    false,
  );

  const accepted = useMemo(() => ['jpeg', 'png', 'gif'], []);

  // support only email supported format: gif, jpeg, png
  // @see: https://www.caniemail.com/search/?s=svg
  const accept = useMemo(
    () =>
      accepted.reduce((acc, mimeType) => {
        return {
          ...acc,
          [`image/${mimeType}`]:
            allAcceptedFileTypes.find(
              (aft) => aft.mimeType === `image/${mimeType}`,
            )?.extensions ?? [],
        };
      }, {}),
    [accepted],
  );

  return (
    <VStack alignItems="start">
      <GenericUploadDropzone
        // force size outside of theme token
        w={w}
        h={h}
        padding={0}
        margin="auto"
        description=""
        {...props}
        renderPreview={({ previewDataUrl, fileName, description }) =>
          previewDataUrl ? (
            <Image
              borderRadius="md"
              objectFit="cover"
              // force size outside of theme token
              w={w}
              h={h}
              src={previewDataUrl}
              alt={fileName}
            />
          ) : (
            <>
              <Icon height={6} width={6} color={contrastColor} as={ImageIcon} />
              <Text
                noOfLines={4}
                color={contrastColor}
                lineHeight="24px"
                wordBreak="break-word"
              >
                {fileName ?? description}
              </Text>
            </>
          )
        }
        accept={accept}
      />
      <Text margin={props.margin} color="gray.500" fontSize="sm">
        Files accepted: {accepted.join(', ')}
      </Text>
    </VStack>
  );
};

/* -------------------------------------------------------------------------- */
/*                               UploadDropZone                               */
/* -------------------------------------------------------------------------- */

export const UploadDropzone = (
  props: Omit<UploadDropzoneProps, 'renderPreview'>,
) =>
  props.type === 'file' ? (
    <FileUploadDropzone {...props} />
  ) : (
    <LogoUploadDropzone {...props} />
  );
