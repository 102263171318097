export const NotificationWorkflowEnum = {
  // Case
  case_created: 'case-created',
  case_assignee_updated: 'case-assignee-updated',
  case_status_updated: 'case-status-updated',
  case_review_due: 'case-review-due',

  // Case report
  case_report_generated: 'case-report-generated',

  // Case progress
  case_waiting_for_new_information: 'case-waiting-for-new-information',
  case_ready_for_review: 'case-ready-for-review',
  case_checks_all_approved: 'case-checks-all-approved',

  // Note
  note_comment_created: 'note-comment-created',
  user_mentioned: 'user-mentioned',

  // Other
  webhook_disabled: 'webhook-disabled',
  document_order_completed: 'document-order-completed',
  import_completed: 'import-completed',
  export_completed: 'export-completed',

  // Communication
  communication_received: 'communication-received',
} as const;
export type NotificationWorkflowEnum =
  (typeof NotificationWorkflowEnum)[keyof typeof NotificationWorkflowEnum];
