import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useHandleError } from '@dotfile/frontend/shared/components';
import { useToast } from '@dotfile/frontend/shared/design-system';
import { formatDateTime, nameFormat } from '@dotfile/shared/common';
import {
  CaseChecksQueryDocument,
  ClientPortalChecks_Individual,
  useShareClientPortalLinkMutation,
} from '@dotfile/shared/data-access-client-portal';

import { FormattedTrans } from '../../../../shared';

// @NOTE: Cannot use useCopyToClipboard since we need to access dynamic value
// Chakra useClipboard is lagging a render between the setValue and the copy
export const useShareClientPortalLink = () => {
  const [runMutation, result] = useShareClientPortalLinkMutation();
  const handleError = useHandleError();
  const { t } = useTranslation();
  const toast = useToast();

  const shareClientPortalLink = useCallback(
    async (
      input: Pick<
        ClientPortalChecks_Individual,
        'id' | 'email' | 'firstName' | 'lastName'
      >,
    ) => {
      try {
        const { data } = await runMutation({
          variables: {
            input: {
              individualId: input.id,
            },
          },
          refetchQueries: [CaseChecksQueryDocument],
          awaitRefetchQueries: true,
        });

        const { expiresAt, link } = data?.shareClientPortalLink ?? {};
        if (link && expiresAt) {
          await navigator.clipboard.writeText(link ?? '');
          const name = nameFormat(input.firstName, input.lastName);
          const date = formatDateTime(expiresAt);
          toast({
            title: t('collaboration.link_copied.title', {
              defaultValue: 'Link copied to clipboard',
              ns: 'client-portal',
            }),
            description: (
              <FormattedTrans
                i18nKey="collaboration.link_copied.description"
                values={{
                  name,
                  email: input.email as string,
                  date,
                }}
                ns="client-portal"
                defaultValue={`Link is valid until <b>${date}</b> for <b>${name}</b> (${input.email})`}
              />
            ),
            status: 'success',
            duration: 5000,
          });
        }
      } catch (error) {
        handleError({
          error: error,
          title: (error as Error).message,
        });
      }

      return null;
    },
    [runMutation, handleError],
  );

  return [shareClientPortalLink, result] as const;
};
