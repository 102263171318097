// eslint-disable-next-line @typescript-eslint/no-var-requires
const short = require('short-uuid');

export const API_KEY_TOKEN_SEPARATOR = '.';

const translator = short(short.constants.flickrBase58);

export interface ApiKeyTokenParts {
  /**
   * The api key secret (including prefix)
   * @see libs/shared/models/domain/src/lib/features/api-key/generate-api-key-secret.ts
   */
  secret: string;
  id: string;
}

/**
 * Build the full api token from the secret and id
 * @param {ApiKeyTokenParts} param The api key secret (including prefix) and id
 * @returns {string}
 */
export function buildApiKeyToken({ secret, id }: ApiKeyTokenParts): string {
  const token = secret + API_KEY_TOKEN_SEPARATOR + translator.fromUUID(id);

  return token;
}

/**
 * Parse the secret and api key id from a api key token
 * @param {string} token
 * @returns {ApiKeyTokenParts}
 * @throws {Error} When the token has not the expected number of separator or on any other error
 */
export function parseApiKeyToken(token: string): ApiKeyTokenParts {
  const splitted = token.split(API_KEY_TOKEN_SEPARATOR);

  if (splitted.length !== 3) {
    throw new Error(`Unexpected token part: ${splitted.length}`);
  }

  const secret = splitted[0] + API_KEY_TOKEN_SEPARATOR + splitted[1];
  const id = translator.toUUID(splitted[2]);

  return { secret, id };
}
