import { PropertyTypeEnum, yup } from '@dotfile/shared/domain';

import { ValidationSchemaFn } from './types';

export const addressValidationSchema: ValidationSchemaFn<
  typeof PropertyTypeEnum.address
> = ({ isRequired, localizedValidation }) => {
  const streetAddress = yup
    .string()
    .nullable()
    .label(localizedValidation.address.street_address());
  const streetAddress2 = yup.string().nullable();
  const postalCode = yup
    .string()
    .nullable()
    .label(localizedValidation.address.postal_code());
  const city = yup
    .string()
    .nullable()
    .label(localizedValidation.address.city());
  const state = yup.string().nullable();
  const country = yup
    .string()
    .countryCode2()
    .label(localizedValidation.address.country());

  const schema = yup
    .object({
      streetAddress: isRequired
        ? streetAddress.required(localizedValidation.required)
        : streetAddress,
      streetAddress2: streetAddress2,
      postalCode: isRequired
        ? postalCode.required(localizedValidation.required)
        : postalCode,
      city: isRequired ? city.required(localizedValidation.required) : city,
      state: state,
      country: isRequired
        ? country
            .required(localizedValidation.required)
            .typeError(localizedValidation.required)
        : country.nullable(),
    })
    .defined();

  return schema;
};
