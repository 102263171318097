import { memo } from 'react';
import { match } from 'ts-pattern';

import {
  Alert,
  AlertIcon,
  AlertTitle,
  GridItem,
  GridItemProps,
} from '@dotfile/frontend/shared/design-system';
import {
  ClientPortalBlockLayoutWidgetEnum,
  ClientPortalForms_BlockLayout,
} from '@dotfile/shared/data-access-client-portal';

import { LayoutDivider, LayoutPdf, LayoutProps, LayoutText } from './layout';

type BlockLayoutProps = {
  layout: ClientPortalForms_BlockLayout;
} & GridItemProps;

export const _BlockLayout = ({
  layout,
  ...gridItemProps
}: BlockLayoutProps) => {
  const props: LayoutProps = { layout };

  const colSpan = 2; // Always 2 columns for layout

  return (
    <GridItem colSpan={colSpan} {...gridItemProps}>
      {match(layout.widget)
        .with(ClientPortalBlockLayoutWidgetEnum.divider, () => (
          <LayoutDivider {...props} />
        ))
        .with(ClientPortalBlockLayoutWidgetEnum.text, () => (
          <LayoutText {...props} />
        ))
        .with(ClientPortalBlockLayoutWidgetEnum.pdf, () => (
          <LayoutPdf {...props} />
        ))
        .otherwise((widget) => (
          <Alert status="warning">
            <AlertIcon />
            <AlertTitle mr={2}>
              Widget {`'`}
              {widget}
              {`'`} not implemented yet for layout {layout.id}
            </AlertTitle>
          </Alert>
        ))}
    </GridItem>
  );
};

export const BlockLayout = memo(_BlockLayout);
BlockLayout.displayName = 'BlockLayout';
