import { createRef, memo, useEffect } from 'react';

import { useIsMobile } from '@dotfile/frontend/shared/design-system';

import { UBBLE_IFRAME_HEIGHT, UBBLE_IFRAME_HEIGHT_MOBILE } from './constants';

/**
 *
 * @NOTE no TypeScript SDK available for Ubble module
 * Since it's loaded from global, we will declare our own
 * @see https://docs.ubble.ai/#section/Manage-applicant-redirection/Redirect-using-an-iframe-(Optional)
 */
declare const Ubble: {
  IDV: new (
    ref: HTMLDivElement | null,
    options: {
      allowCamera: boolean;
      verificationUrl: string;
      width?: string | number;
      height?: string | number;
      events: Partial<{
        onComplete: () => void;
        onExpired: () => void;
        onAbort: () => void;
        onRefused: () => void;
      }>;
    },
  ) => {
    destroy: () => void;
  };
};

interface UbbleIframeProps {
  verificationUrl: string;
  onComplete: () => void;
  onAbort: () => void;
  onExpired: () => void;
}

// Pure component to not rerender if url don't change
// Make sure you wrap handlers in useCallback to not trigger rerender
export const UbbleCheckoutIframe = memo(function UbbleCheckoutIframe({
  verificationUrl,
  onComplete,
  onAbort,
  onExpired,
}: UbbleIframeProps) {
  const isMobile = useIsMobile();
  const ref = createRef<HTMLDivElement>();

  useEffect(() => {
    const ubble = new Ubble.IDV(ref.current, {
      width: '100%',
      height: isMobile ? UBBLE_IFRAME_HEIGHT_MOBILE : UBBLE_IFRAME_HEIGHT,
      verificationUrl: verificationUrl,
      allowCamera: true,
      events: {
        onComplete: () => {
          ubble.destroy();
          onComplete();
        },
        onAbort: () => {
          ubble.destroy();
          onAbort();
        },
        onRefused: () => {
          ubble.destroy();
          onAbort();
        },
        onExpired: () => {
          ubble.destroy();
          onExpired();
        },
      },
    });

    return () => {
      ubble.destroy();
    };
  }, [verificationUrl, ref, onComplete, onAbort, onExpired, isMobile]);

  return <div ref={ref} className="iframe-ref" />;
});
