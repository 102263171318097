import { createLinks } from './create-links';

// Search params constants
export const TOKEN_SEARCH_PARAMS = 'token';

// Here we list all our application pages
export const consoleRoutes = {
  AppArea: '/*',
  AppRoot: '/',

  // Auth
  Login: '/login?:redirect',
  ForgetPassword: '/forget-password',
  ResetPassword: `/reset-password?:${TOKEN_SEARCH_PARAMS}`,
  Register: '/register?:redirect',
  RedeemInvite: `/redeem-invite?:${TOKEN_SEARCH_PARAMS}`,

  // Console
  ConsoleBase: '/console',
  ConsoleArea: '/console/*',

  // User
  Mfa: '/console/mfa?:redirect',
  ConfirmEmail: `/console/confirm-email?:${TOKEN_SEARCH_PARAMS}`,

  // Account
  AccountArea: '/console/account/*?:workspaceId',
  AccountBase: '/console/account?:workspaceId&:back',
  AccountProfile: '/console/account/profile?:workspaceId',
  AccountSecurity: '/console/account/security?:workspaceId',
  AccountNotification: '/console/account/notification?:workspaceId',

  // Workspace
  CreateWorkspace: '/console/create-workspace?:back',
  EnableMfa: '/console/:workspaceId/enable-mfa?:redirect',
  WorkspaceBase: '/console/:workspaceId',
  WorkspaceArea: '/console/:workspaceId/*',

  // Workspace settings
  SettingsArea: '/console/:workspaceId/settings/*',
  SettingsBase: '/console/:workspaceId/settings?:back',
  SettingsWorkspace: '/console/:workspaceId/settings/workspace',

  // Workspace settings > Users
  SettingsUsersArea: '/console/:workspaceId/settings/users/*',
  SettingsUsersBase: '/console/:workspaceId/settings/users',
  SettingsUsersMembers: '/console/:workspaceId/settings/users/members',
  SettingsUsersRole: '/console/:workspaceId/settings/users/roles',
  SettingsUsersSecurity: '/console/:workspaceId/settings/users/security',
  SettingsCreateCustomRole: '/console/:workspaceId/settings/users/roles/create',
  SettingsUpdateCustomRole:
    '/console/:workspaceId/settings/users/roles/:customRoleId',

  SettingsTags: '/console/:workspaceId/settings/tags?:sort&:filters&:search',

  SettingsDotfileAIArea: '/console/:workspaceId/settings/dotfile-ai/*',
  SettingsDotfileAIBase: '/console/:workspaceId/settings/dotfile-ai',
  SettingsDotfileAIDocumentAnalysis:
    '/console/:workspaceId/settings/dotfile-ai/document-analysis',
  SettingsDotfileAIAml: '/console/:workspaceId/settings/dotfile-ai/aml',
  SettingsDotfileAIPreference:
    '/console/:workspaceId/settings/dotfile-ai/preference',

  // Workspace settings > Checks
  SettingsChecksArea: '/console/:workspaceId/settings/checks/*',
  SettingsChecksBase: '/console/:workspaceId/settings/checks',
  SettingsChecksAml: '/console/:workspaceId/settings/checks/aml',
  SettingsChecksDocument:
    '/console/:workspaceId/settings/checks/document?:sort&:filters&:search',
  SettingsChecksIdDocument: '/console/:workspaceId/settings/checks/id-document',
  SettingsChecksIdVerification:
    '/console/:workspaceId/settings/checks/id-verification',

  // Workspace settings > Properties
  SettingsPropertiesArea: '/console/:workspaceId/settings/properties/*',
  SettingsPropertiesBase: '/console/:workspaceId/settings/properties',
  SettingsPropertiesEntity:
    '/console/:workspaceId/settings/properties/:targetEntity',
  SettingsPropertiesEntityCreate:
    '/console/:workspaceId/settings/properties/:targetEntity/create',
  SettingsPropertiesEntityUpdate:
    '/console/:workspaceId/settings/properties/:targetEntity/:customPropertyId',

  //  SettingsCommunicationCompanyData:
  SettingsCommunicationArea: '/console/:workspaceId/settings/communication/*',
  SettingsCommunicationBase: '/console/:workspaceId/settings/communication',
  SettingsCommunicationSender:
    '/console/:workspaceId/settings/communication/sender',
  SettingsCommunicationAutomated:
    '/console/:workspaceId/settings/communication/automated',

  // Workspace settings > Advanced features
  SettingsAdvancedFeaturesArea:
    '/console/:workspaceId/settings/advanced-features/*',
  SettingsAdvancedFeaturesBase:
    '/console/:workspaceId/settings/advanced-features',
  SettingsAdvancedFeaturesCaseApprovalFlow:
    '/console/:workspaceId/settings/advanced-features/case-approval-flow',
  SettingsAdvancedFeaturesRisk:
    '/console/:workspaceId/settings/advanced-features/risk',
  SettingsAdvancedFeaturesOnboardingFlow:
    '/console/:workspaceId/settings/advanced-features/onboarding-flow',
  SettingsAdvancedFeaturesCompanyData:
    '/console/:workspaceId/settings/advanced-features/company-data',

  // Workspace settings > Tables
  SettingsTableArea: '/console/:workspaceId/settings/tables/*',
  SettingsTableList: '/console/:workspaceId/settings/tables',
  SettingsTableDetail:
    '/console/:workspaceId/settings/tables/:tableId?:sort&:filters',

  // Workspace settings > Integrations
  SettingsIntegrations: '/console/:workspaceId/settings/integrations',
  SettingsIntegrationsGoogleSheets:
    '/console/:workspaceId/settings/integrations/google-sheets',

  // Workspace settings > Api keys
  SettingsApiKeys:
    '/console/:workspaceId/settings/api-keys?:sort&:filters&:search',

  // Workspace settings / Webhooks
  SettingsWebhooksArea: '/console/:workspaceId/settings/webhooks/*',
  SettingsWebhooksBase:
    '/console/:workspaceId/settings/webhooks?:sort&:filters&:search',
  SettingsWebhookLogs:
    '/console/:workspaceId/settings/webhooks/logs?:sort&:filters&:logGroupId',

  // Workspace settings / Billing
  SettingsBilling: '/console/:workspaceId/settings/billing',

  // Workspace settings > Client portals
  SettingsClientPortalArea: '/console/:workspaceId/settings/client-portal/*',
  SettingsClientPortalList: '/console/:workspaceId/settings/client-portal',
  SettingsClientPortalDetailArea:
    '/console/:workspaceId/settings/client-portal/:clientPortalId/*',
  SettingsClientPortalDetailBase:
    '/console/:workspaceId/settings/client-portal/:clientPortalId',
  SettingsClientPortalDetailForm:
    '/console/:workspaceId/settings/client-portal/:clientPortalId/form?:step',
  SettingsClientPortalDetailSettings:
    '/console/:workspaceId/settings/client-portal/:clientPortalId/settings',
  SettingsClientPortalDetailWorkflow:
    '/console/:workspaceId/settings/client-portal/:clientPortalId/workflow',
  SettingsClientPortalDetailStyling:
    '/console/:workspaceId/settings/client-portal/:clientPortalId/styling',
  SettingsClientPortalDetailWordings:
    '/console/:workspaceId/settings/client-portal/:clientPortalId/wordings',
  SettingsClientPortalDetailTranslation:
    '/console/:workspaceId/settings/client-portal/:clientPortalId/translation',

  // Individuals
  IndividualsArea: '/console/:workspaceId/individuals/*',
  IndividualsList:
    '/console/:workspaceId/individuals?:sort&:filters&:search&:viewId',

  // Companies
  CompaniesArea: '/console/:workspaceId/companies/*',
  CompaniesList:
    '/console/:workspaceId/companies?:sort&:filters&:search&:viewId',

  // Cases
  CasesListArea: '/console/:workspaceId/cases/*',
  CasesList: '/console/:workspaceId/cases?:sort&:filters&:search&:viewId',

  // CaseDetail
  CaseDetailArea: '/console/:workspaceId/cases/:caseId/*',
  CaseDetailBase: '/console/:workspaceId/cases/:caseId',
  CaseDetailSummary:
    '/console/:workspaceId/cases/:caseId/summary?:noteId&:noteCommentId&:dataComparison',
  CaseDetailGraph: '/console/:workspaceId/cases/:caseId/graph',
  CaseDetailRelation:
    '/console/:workspaceId/cases/:caseId/graph/relations/:caseRelationId?:targetId',
  CaseDetailInterestInMainCompany:
    '/console/:workspaceId/cases/:caseId/graph/interests/:individualId',
  CaseDetailCompanies: '/console/:workspaceId/cases/:caseId/companies',
  CaseDetailCompany:
    '/console/:workspaceId/cases/:caseId/companies/:companyId?:fileId&:companyData',
  CaseDetailIndividuals: '/console/:workspaceId/cases/:caseId/individuals',
  CaseDetailIndividual:
    '/console/:workspaceId/cases/:caseId/individuals/:individualId?:dataId&:file',
  CaseDetailCommunications:
    '/console/:workspaceId/cases/:caseId/communications?:threadId&:communicationId',
  CaseDetailActivity: '/console/:workspaceId/cases/:caseId/activity',

  // Checks
  ChecksListArea: '/console/:workspaceId/checks/*',
  ChecksList: '/console/:workspaceId/checks?:sort&:filters&:viewId',

  // CheckDetail
  CheckDetailArea: '/console/:workspaceId/checks/:checkId/*',
  CheckDetailBase:
    '/console/:workspaceId/checks/:checkId?:context&:dataId&:fileId&:amlHitId',

  // Template
  TemplatesArea: '/console/:workspaceId/templates/*',
  TemplatesList: '/console/:workspaceId/templates?:sort&:filters&:search',
  CreateTemplate: '/console/:workspaceId/templates/create',
  UpdateTemplate: '/console/:workspaceId/templates/:templateId',

  // Analytics
  AnalyticsDashboardArea: '/console/:workspaceId/analytics/*',
  AnalyticsDashboardBase: '/console/:workspaceId/analytics',
} as const;

export const ConsoleLinks = createLinks(consoleRoutes);
